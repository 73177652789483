import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { Actions, Select, ofAction, ofActionSuccessful } from '@ngxs/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { Auth, AuthState } from './modules/auth/auth.state';
import { Title } from '@angular/platform-browser';
import { Json } from './services/database.types';
import { AppState } from './app.state';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet],
})
export class AppComponent implements OnInit, OnDestroy {
  ngUnsubscribe = new Subject<void>();

  @Select(AppState.customization)
  boardingCustomization$: Observable<Json | null>;

  constructor(
    private actions: Actions,
    private router: Router,
    private titleService: Title,
  ) {
    this.boardingCustomization$.pipe(
      takeUntil(this.ngUnsubscribe),
    ).subscribe((customization) => {
      const title = customization?.['app-title'];
      const favicon = customization?.['favicon'];

      if (favicon) {
        const favIcon: HTMLLinkElement = document.querySelector('#favIcon');
        favIcon.href = favicon;
      }

      if (title) {
        this.titleService.setTitle(title);
      }
    });
  }

  ngOnInit(): void {
    this.actions.pipe(
      ofActionSuccessful(Auth.Logout),
      takeUntil(this.ngUnsubscribe),
    ).subscribe(() => {
      this.router.navigate(['/sign-out']);
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
