/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
  {
    id: 'scoring',
    title: 'Scoring',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/scoring'
  },
  {
    id: 'admin',
    title: 'Administration',
    type: 'basic',
    icon: 'heroicons_outline:squares-plus',
    link: '/admin'
  }
];
export const compactNavigation: FuseNavigationItem[] = [
  {
    id: 'example',
    title: 'Example',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/example'
  }
];
export const futuristicNavigation: FuseNavigationItem[] = [
  {
    id: 'example',
    title: 'Example',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/example'
  }
];
export const horizontalNavigation: FuseNavigationItem[] = [
  {
    id: 'scoring',
    title: 'Scoring',
    type: 'basic',
    icon: 'heroicons_outline:swatch',
    link: '/scoring'
  },
];
