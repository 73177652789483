import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { Api } from 'app/services/api.service';

export const NoAuthGuard: CanActivateFn | CanActivateChildFn = async (route, state) => {
  const api = inject(Api);
  const router = inject(Router);

  try {
    const { data, error } = await api.supabase.auth.getSession();
    if (!data.session || error) {
      return true;
    }
    return router.parseUrl('');
  } catch (error) {
    return true;
  }
};
