import { NgIf } from '@angular/common'
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core'
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router'
import { FuseLoadingBarComponent } from '@fuse/components/loading-bar'
import { Store } from '@ngxs/store'
import { Auth } from 'app/modules/auth/auth.state'
import { Subject, takeUntil } from 'rxjs'

@Component({
  selector: 'empty-layout',
  templateUrl: './empty.component.html',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [FuseLoadingBarComponent, NgIf, RouterOutlet],
})
export class EmptyLayoutComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(private activatedRoute: ActivatedRoute, private store: Store) {
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.pipe(
      takeUntil(this._unsubscribeAll),
    ).subscribe((params) => {
      console.warn(params)
      if (params['boarding']) {
        this.store.dispatch(new Auth.SetBoardingContext({ contextId: params['boarding'] }))
      }
    })
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null)
    this._unsubscribeAll.complete()
  }
}
