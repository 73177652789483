import * as i0 from '@angular/core';
import { Injectable, Directive, Input, NgModule } from '@angular/core';
import * as i1 from '@ngxs/store';
import { getActionTypeFromInstance, setValue, getValue, ofActionDispatched, NGXS_PLUGINS } from '@ngxs/store';
import * as i2 from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { Subject } from 'rxjs';
import { filter, takeUntil, distinctUntilChanged, debounceTime } from 'rxjs/operators';
class UpdateFormStatus {
  constructor(payload) {
    this.payload = payload;
  }
  static get type() {
    // NOTE: Not necessary to declare the type in this way in your code. See https://github.com/ngxs/store/pull/644#issuecomment-436003138
    return '[Forms] Update Form Status';
  }
}
class UpdateFormValue {
  constructor(payload) {
    this.payload = payload;
  }
  static get type() {
    // NOTE: Not necessary to declare the type in this way in your code. See https://github.com/ngxs/store/pull/644#issuecomment-436003138
    return '[Forms] Update Form Value';
  }
}
class UpdateForm {
  constructor(payload) {
    this.payload = payload;
  }
  static get type() {
    // NOTE: Not necessary to declare the type in this way in your code. See https://github.com/ngxs/store/pull/644#issuecomment-436003138
    return '[Forms] Update Form';
  }
}
class UpdateFormDirty {
  constructor(payload) {
    this.payload = payload;
  }
  static get type() {
    // NOTE: Not necessary to declare the type in this way in your code. See https://github.com/ngxs/store/pull/644#issuecomment-436003138
    return '[Forms] Update Form Dirty';
  }
}
class SetFormDirty {
  constructor(payload) {
    this.payload = payload;
  }
  static get type() {
    // NOTE: Not necessary to declare the type in this way in your code. See https://github.com/ngxs/store/pull/644#issuecomment-436003138
    return '[Forms] Set Form Dirty';
  }
}
class SetFormPristine {
  constructor(payload) {
    this.payload = payload;
  }
  static get type() {
    // NOTE: Not necessary to declare the type in this way in your code. See https://github.com/ngxs/store/pull/644#issuecomment-436003138
    return '[Forms] Set Form Pristine';
  }
}
class UpdateFormErrors {
  constructor(payload) {
    this.payload = payload;
  }
  static get type() {
    // NOTE: Not necessary to declare the type in this way in your code. See https://github.com/ngxs/store/pull/644#issuecomment-436003138
    return '[Forms] Update Form Errors';
  }
}
class SetFormDisabled {
  constructor(payload) {
    this.payload = payload;
  }
  static get type() {
    // NOTE: Not necessary to declare the type in this way in your code. See https://github.com/ngxs/store/pull/644#issuecomment-436003138
    return '[Forms] Set Form Disabled';
  }
}
class SetFormEnabled {
  constructor(payload) {
    this.payload = payload;
  }
  static get type() {
    // NOTE: Not necessary to declare the type in this way in your code. See https://github.com/ngxs/store/pull/644#issuecomment-436003138
    return '[Forms] Set Form Enabled';
  }
}
class ResetForm {
  constructor(payload) {
    this.payload = payload;
  }
  static get type() {
    // NOTE: Not necessary to declare the type in this way in your code. See https://github.com/ngxs/store/pull/644#issuecomment-436003138
    return '[Forms] Reset Form';
  }
}
class NgxsFormPlugin {
  handle(state, event, next) {
    const type = getActionTypeFromInstance(event);
    let nextState = state;
    if (type === UpdateFormValue.type || type === UpdateForm.type || type === ResetForm.type) {
      const {
        value
      } = event.payload;
      const payloadValue = Array.isArray(value) ? value.slice() : isObjectLike(value) ? Object.assign({}, value) : value;
      const path = this.joinPathWithPropertyPath(event);
      nextState = setValue(nextState, path, payloadValue);
    }
    if (type === ResetForm.type) {
      const model = getValue(nextState, `${event.payload.path}.model`);
      nextState = setValue(nextState, `${event.payload.path}`, {
        model: model
      });
    }
    if (type === UpdateFormStatus.type || type === UpdateForm.type) {
      nextState = setValue(nextState, `${event.payload.path}.status`, event.payload.status);
    }
    if (type === UpdateFormErrors.type || type === UpdateForm.type) {
      nextState = setValue(nextState, `${event.payload.path}.errors`, Object.assign({}, event.payload.errors));
    }
    if (type === UpdateFormDirty.type || type === UpdateForm.type) {
      nextState = setValue(nextState, `${event.payload.path}.dirty`, event.payload.dirty);
    }
    if (type === SetFormDirty.type) {
      nextState = setValue(nextState, `${event.payload}.dirty`, true);
    }
    if (type === SetFormPristine.type) {
      nextState = setValue(nextState, `${event.payload}.dirty`, false);
    }
    if (type === SetFormDisabled.type) {
      nextState = setValue(nextState, `${event.payload}.disabled`, true);
    }
    if (type === SetFormEnabled.type) {
      nextState = setValue(nextState, `${event.payload}.disabled`, false);
    }
    return next(nextState, event);
  }
  joinPathWithPropertyPath({
    payload
  }) {
    let path = `${payload.path}.model`;
    if (payload.propertyPath) {
      path += `.${payload.propertyPath}`;
    }
    return path;
  }
}
/** @nocollapse */
NgxsFormPlugin.ɵfac = function NgxsFormPlugin_Factory(t) {
  return new (t || NgxsFormPlugin)();
};
/** @nocollapse */
NgxsFormPlugin.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: NgxsFormPlugin,
  factory: NgxsFormPlugin.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxsFormPlugin, [{
    type: Injectable
  }], null, null);
})();
function isObjectLike(target) {
  return target !== null && typeof target === 'object';
}
class FormDirective {
  constructor(_actions$, _store, _formGroupDirective, _cd) {
    this._actions$ = _actions$;
    this._store = _store;
    this._formGroupDirective = _formGroupDirective;
    this._cd = _cd;
    this.path = null;
    this._debounce = 100;
    this._clearDestroy = false;
    this._updating = false;
    this._destroy$ = new Subject();
  }
  set debounce(debounce) {
    this._debounce = Number(debounce);
  }
  get debounce() {
    return this._debounce;
  }
  set clearDestroy(val) {
    this._clearDestroy = val != null && `${val}` !== 'false';
  }
  get clearDestroy() {
    return this._clearDestroy;
  }
  ngOnInit() {
    this._actions$.pipe(ofActionDispatched(ResetForm), filter(action => action.payload.path === this.path), takeUntil(this._destroy$)).subscribe(({
      payload: {
        value
      }
    }) => {
      this.form.reset(value);
      this.updateFormStateWithRawValue(true);
      this._cd.markForCheck();
    });
    this.getStateStream(`${this.path}.model`).subscribe(model => {
      if (this._updating || !model) {
        return;
      }
      this.form.patchValue(model);
      this._cd.markForCheck();
    });
    this.getStateStream(`${this.path}.dirty`).subscribe(dirty => {
      if (this.form.dirty === dirty || typeof dirty !== 'boolean') {
        return;
      }
      if (dirty) {
        this.form.markAsDirty();
      } else {
        this.form.markAsPristine();
      }
      this._cd.markForCheck();
    });
    // On first state change, sync form model, status and dirty with state
    this._store.selectOnce(state => getValue(state, this.path)).subscribe(() => {
      this._store.dispatch([new UpdateFormValue({
        path: this.path,
        value: this.form.getRawValue()
      }), new UpdateFormStatus({
        path: this.path,
        status: this.form.status
      }), new UpdateFormDirty({
        path: this.path,
        dirty: this.form.dirty
      })]);
    });
    this.getStateStream(`${this.path}.disabled`).subscribe(disabled => {
      if (this.form.disabled === disabled || typeof disabled !== 'boolean') {
        return;
      }
      if (disabled) {
        this.form.disable();
      } else {
        this.form.enable();
      }
      this._cd.markForCheck();
    });
    this._formGroupDirective.valueChanges.pipe(distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)), this.debounceChange()).subscribe(() => {
      this.updateFormStateWithRawValue();
    });
    this._formGroupDirective.statusChanges.pipe(distinctUntilChanged(), this.debounceChange()).subscribe(status => {
      this._store.dispatch(new UpdateFormStatus({
        status,
        path: this.path
      }));
    });
  }
  updateFormStateWithRawValue(withFormStatus) {
    if (this._updating) return;
    const value = this._formGroupDirective.control.getRawValue();
    const actions = [new UpdateFormValue({
      path: this.path,
      value
    }), new UpdateFormDirty({
      path: this.path,
      dirty: this._formGroupDirective.dirty
    }), new UpdateFormErrors({
      path: this.path,
      errors: this._formGroupDirective.errors
    })];
    if (withFormStatus) {
      actions.push(new UpdateFormStatus({
        path: this.path,
        status: this._formGroupDirective.status
      }));
    }
    this._updating = true;
    this._store.dispatch(actions).subscribe({
      error: () => this._updating = false,
      complete: () => this._updating = false
    });
  }
  ngOnDestroy() {
    this._destroy$.next();
    if (this.clearDestroy) {
      this._store.dispatch(new UpdateForm({
        path: this.path,
        value: null,
        dirty: null,
        status: null,
        errors: null
      }));
    }
  }
  debounceChange() {
    const skipDebounceTime = this._formGroupDirective.control.updateOn !== 'change' || this._debounce < 0;
    return skipDebounceTime ? change => change.pipe(takeUntil(this._destroy$)) : change => change.pipe(debounceTime(this._debounce), takeUntil(this._destroy$));
  }
  get form() {
    return this._formGroupDirective.form;
  }
  getStateStream(path) {
    return this._store.select(state => getValue(state, path)).pipe(takeUntil(this._destroy$));
  }
}
/** @nocollapse */
FormDirective.ɵfac = function FormDirective_Factory(t) {
  return new (t || FormDirective)(i0.ɵɵdirectiveInject(i1.Actions), i0.ɵɵdirectiveInject(i1.Store), i0.ɵɵdirectiveInject(i2.FormGroupDirective), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
};
/** @nocollapse */
FormDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: FormDirective,
  selectors: [["", "ngxsForm", ""]],
  inputs: {
    path: [i0.ɵɵInputFlags.None, "ngxsForm", "path"],
    debounce: [i0.ɵɵInputFlags.None, "ngxsFormDebounce", "debounce"],
    clearDestroy: [i0.ɵɵInputFlags.None, "ngxsFormClearOnDestroy", "clearDestroy"]
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormDirective, [{
    type: Directive,
    args: [{
      selector: '[ngxsForm]'
    }]
  }], function () {
    return [{
      type: i1.Actions
    }, {
      type: i1.Store
    }, {
      type: i2.FormGroupDirective
    }, {
      type: i0.ChangeDetectorRef
    }];
  }, {
    path: [{
      type: Input,
      args: ['ngxsForm']
    }],
    debounce: [{
      type: Input,
      args: ['ngxsFormDebounce']
    }],
    clearDestroy: [{
      type: Input,
      args: ['ngxsFormClearOnDestroy']
    }]
  });
})();
class NgxsFormPluginModule {
  static forRoot() {
    return {
      ngModule: NgxsFormPluginModule,
      providers: [{
        provide: NGXS_PLUGINS,
        useClass: NgxsFormPlugin,
        multi: true
      }]
    };
  }
}
/** @nocollapse */
NgxsFormPluginModule.ɵfac = function NgxsFormPluginModule_Factory(t) {
  return new (t || NgxsFormPluginModule)();
};
/** @nocollapse */
NgxsFormPluginModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: NgxsFormPluginModule
});
/** @nocollapse */
NgxsFormPluginModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[ReactiveFormsModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxsFormPluginModule, [{
    type: NgModule,
    args: [{
      imports: [ReactiveFormsModule],
      declarations: [FormDirective],
      exports: [FormDirective]
    }]
  }], null, null);
})();

/**
 * The public api for consumers of @ngxs/form-plugin
 */

/**
 * Generated bundle index. Do not edit.
 */

export { NgxsFormPlugin, NgxsFormPluginModule, ResetForm, SetFormDirty, SetFormDisabled, SetFormEnabled, SetFormPristine, UpdateForm, UpdateFormDirty, UpdateFormErrors, UpdateFormStatus, UpdateFormValue, FormDirective as ɵFormDirective };
