import { APP_INITIALIZER, EnvironmentProviders, inject, Provider } from '@angular/core'
import { provideTransloco, TranslocoService } from '@jsverse/transloco'
import { provideTranslocoLocale } from '@jsverse/transloco-locale'
import { provideTranslocoMessageformat } from '@jsverse/transloco-messageformat'
import { provideTranslocoPreloadLangs } from '@jsverse/transloco-preload-langs'
import { Store } from '@ngxs/store'
import { AppState } from 'app/app.state'
import { TranslocoHttpLoader } from 'app/core/transloco/transloco.http-loader'
import { environment } from 'environments/environment'
import { lastValueFrom } from 'rxjs'

export const provideTranslation = (): Array<Provider | EnvironmentProviders> => {
  return [
    provideTransloco({
      config: {
        availableLangs: [{ id: 'fr', label: 'Français' }, { id: 'en', label: 'English' }, { id: 'de', label: 'Deutsch' }, { id: 'es', label: 'Español' }, { id: 'it', label: 'Italiano' }, { id: 'nl', label: 'Nederlands' }],
        defaultLang: 'fr',
        fallbackLang: 'en',
        reRenderOnLangChange: true,
        prodMode: environment.production,
      },
      loader: TranslocoHttpLoader,
    }),
    provideTranslocoPreloadLangs(['fr', 'en', 'de', 'es', 'it', 'nl']),
    provideTranslocoMessageformat(),
    provideTranslocoLocale({
      langToLocaleMapping: {
        en: 'en-US',
        fr: 'fr-FR',
        de: 'de-DE',
        es: 'es-ES',
      }
    }),
    {
      // Preload the default language before the app starts to prevent empty/jumping content
      provide: APP_INITIALIZER,
      useFactory: () => {
        const translocoService = inject(TranslocoService)
        const store = inject(Store)
        const persistedLang = store.selectSnapshot(AppState.language)

        const defaultLang = persistedLang ?? translocoService.getDefaultLang()
        translocoService.setActiveLang(defaultLang)

        return () => lastValueFrom(translocoService.load(defaultLang))
      },
      multi: true,
    },
  ]
}
